<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="p-2">
      <h5 class="required text-xl text-black">{{ $t("Tỷ lệ Điểm nạp") }}</h5>
      <div class="flex flex-col md:flex-row gap-2 md:gap-4 md:items-center">
        <el-input
          :placeholder="$t('Nhập số tiền chuyển đổi')"
          class="cs-input flex-1"
          v-model="form.moneyValue"
          @input="handleChangeMoneyValue"
        >
          <template slot="append">VND</template>
        </el-input>
        <svg
          class="cs-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 2048 2048"
        >
          <path
            fill="currentColor"
            d="M2048 1408v128H250l163 163l-90 90L6 1472l317-317l90 90l-163 163h1798zm-413-605l163-163H0V512h1798l-163-163l90-90l317 317l-317 317l-90-90z"
          />
        </svg>
        <el-input
          placeholder="Nhập số điểm chuyển đổi"
          class="cs-input flex-1"
          v-model="form.pointsValue"
          @input="handleChangePointValue"
        >
          <template slot="append">{{ $t("Điểm") }}</template>
        </el-input>
      </div>
      <div
        class="mt-1"
        v-if="
          configData.change_by_user &&
          configData.change_by_user.name &&
          formatTimeUpdate
        "
      >
        <span>{{ $t("Cập nhật lần cuối: ") }}</span>
        <strong>{{ formatTimeUpdate }}</strong>
        bởi
        <strong>{{ configData.change_by_user.name }}</strong>
      </div>
      <div class="flex justify-end items-center gap-2 mt-2">
        <b-button
          variant="primary"
          @click="handleSubmitAccumulate"
          :disabled="isLoading"
        >
          {{ $t("Lưu") }}
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import appUtils from "@/utils/appUtils";
import { Input, Button } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ConfigLayout",
  components: { "el-input": Input },

  data() {
    return {
      form: {
        moneyValue: null,
        pointsValue: null,
      },
      isLoading: false,
      realData: {
        moneyValue: null,
        pointsValue: null,
      },
      configData: {},
    };
  },
  watch: {},
  computed: {
    formatTimeUpdate() {
      if (!this.configData?.updated_at) return;
      return window
        .moment(this.configData.updated_at)
        .format("HH:mm DD/MM/YYYY");
    },
  },
  created() {
    this.getExchangeRateByOrgId();
  },
  methods: {
    async handleSubmitAccumulate() {
      const userData = appUtils.getLocalUser();

      if (!userData.supplierInfo?.org_id) return;

      if (!this.realData.moneyValue || !this.realData.pointsValue) {
        this.$message({
          message: this.$t("Vui lòng điển đầy đủ thông tin bắt buộc"),
          type: "warning",
        });
        return;
      }

      const label = "Bạn có chắc chắn muốn áp dụng tỷ lệ này không?";
      const title = "Thiết lập tỷ lệ";

      this.$bvModal
        .msgBoxConfirm(label, {
          title,
          size: "sm",
          okVariant: "primary",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (!value) return;
          try {
            this.isLoading = true;
            const params = {
              org_id: userData.supplierInfo?.org_id,
              equivalent_point: this.realData.pointsValue,
              bill_value: this.realData.moneyValue,
            };
            const response = await this.$rf
              .getRequest("CommonRequest")
              .configExchangeRate(params);

            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(`${title} thành công`),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          } catch (error) {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(`${title} thất bại`),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } finally {
            this.isLoading = false;
          }
        })
        .catch(() => {});
    },
    handleChangePointValue(value) {
      const newValue = Number(value?.toString()?.replaceAll(",", ""));
      this.form.pointsValue = appUtils.numberFormat(newValue);
      this.realData.pointsValue = newValue;
    },
    handleChangeMoneyValue(value) {
      const newValue = Number(value?.toString()?.replaceAll(",", ""));
      this.form.moneyValue = appUtils.numberFormat(newValue);

      this.realData.moneyValue = newValue;
    },
    async getExchangeRateByOrgId() {
      try {
        const userData = appUtils.getLocalUser();

        if (!userData.supplierInfo?.org_id) return;
        const org_id = userData.supplierInfo?.org_id;

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getExchangeRateDetail(org_id);

        this.configData = response.data;

        this.handleChangePointValue(this.configData?.equivalent_point);
        this.handleChangeMoneyValue(this.configData?.bill_value);
      } catch (error) {
        console.log(error);
      }
    },
    roundToFourDecimals(number) {
      if (!number) return "";
      const isInteger = Number.isInteger(number);

      let result;
      if (isInteger) {
        result = appUtils.numberFormat(number);
      } else {
        const formatNumber = parseFloat(number).toFixed(4);
        const splitNumber = formatNumber.split(".");
        const intNumber = appUtils.numberFormat(splitNumber[0]);
        result = `${intNumber}.${splitNumber[1]}`;
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border: 1px solid #dcdfe6 !important;
      background-color: white !important;
      text-align: right;
    }

    .el-input-group__append {
      background-color: white !important;
    }
  }
}

.cs-icon {
  transform: rotate(90deg);
  align-self: center;
}

@media (max-width: 768px) {
  .cs-hidden-icon {
    display: none;
  }
}
</style>