<template>
  <div class="bg-white h-full">
    <ConfigLayout />
  </div>
</template>

<script>
import ConfigLayout from "./ConfigLayout.vue";
export default {
  components: { ConfigLayout },
  name: "CreditConfig",
};
</script>

<style lang="scss" scoped>
</style>